import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { Link as LinkText } from '@entur/typography';
import { TextField } from '@entur/form';
import { GridItem, GridContainer } from '@entur/grid';
import { SearchIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { textfield } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<TextField></TextField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      LinkText,
      TextField,
      GridItem,
      GridContainer,
      SearchIcon,
      Playground,
      PageHeader,
      ImportStatement,
      textfield,
      DefaultLayout,
      _frontmatter
    }} props={textfield} mdxType="Playground">
  <TextField mdxType="TextField"></TextField>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "textfield"
    }}>{`TextField`}</h3>
    <ImportStatement imports="TextField" mdxType="ImportStatement" />
    <Props of={TextField} mdxType="Props" />
    <p><inlineCode parentName="p">{`TextField`}</inlineCode>{` er en stylet wrapper for et `}<inlineCode parentName="p">{`<input>`}</inlineCode>{`-element.
Dette innebærer at eventuelle props du ønsker bruke fra det grunnleggende HTML-elementet blir videresendt.`}</p>
    <p>{`Les mer om disse her:
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes"
      }}>{`https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes`}</a></p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <div>
  Tekstfelt lar brukerne legge inn og redigere tekst i et brukergrensesnitt.
  Tekstfelt brukes normalt i et skjema, man kan også være del av for eksempel
  modal eller søk. Bør benyttes med en kort og tydelig ledetekst som forklarer
  hva brukeren skal oppgi i feltet. Hvis det er nødvendig med mer veiledning for
  å hjelpe brukerens utfylling av skjemaet, kan du legge til hint, hjelpetekst
  og validering. Anbefalt bredde på tekstfelt bør ikke overstige 500px.
    </div>
    <br />
    <div>
  For tekst som går over flere linjer, bruk heller{' '}
  <LinkText as={Link} to="/komponenter/skjemaelementer/textarea" mdxType="LinkText">
    Text areas
  </LinkText>
  .
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      